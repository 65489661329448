<template>
  <div class="home">
    <Head ref="headSearch" hId="1" @keyup.enter.native="getSearch"></Head>
    <div class="main">
      <div class="content">
        <ul class="tj">
          <li v-for="(citem, idx) in filterText" :key="idx">
            <span>{{ filterText[idx] }}</span>
            <div class="nr">
              <span
                @click="filterClick(idx, ''), hideJobCode(idx)"
                :class="{ atthis: filter[idx] === '' }"
                >不限</span
              >
              <span
                @click="
                  filterIobCode(idx, item.dictCode),
                    filterClick(idx, item.dictCode)
                "
                :class="{ atthis: filter[idx] === item.dictCode }"
                v-for="item in searchFilter[idx]"
                :key="item.dictValue"
                >{{ item.dictValue }}</span
              >
            </div>
          </li>
        </ul>
        <div class="tabStyle">
          <div class="title-tag">
            <img src="@/assets/img/recruit/icon-search.svg" alt="人才搜索" />
          </div>
          <div class="cont" v-if="total">
            <Resume :dataList="searchRes" />
          </div>
          <el-empty
            style="padding: 60px 0 300px"
            v-if="!total"
            :image-size="100"
          ></el-empty>
          <el-footer class="listPage" v-if="total > 0">
            <el-pagination
              :total="total"
              :current-page="filter.pageNum"
              :page-size="filter.pageSize"
              layout="total, prev, pager, next, jumper"
              @size-change="sizeChange"
              @current-change="currentChange"
            ></el-pagination>
          </el-footer>
          <div class="title-tag">
            <img src="@/assets/img/recruit/icon-rc.svg" alt="推荐人才" />
          </div>
          <div class="cont">
            <Resume :dataList="recommendList" />
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { recommend, codition, search } from "@/api/zp";
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Resume from "@/components/Resume";
import { jobListPage } from "@/api/myResume";

export default {
  data() {
    return {
      recommendList: [],
      searchFilter: {},
      searchRes: [],
      total: 0,
      filterText: {
        recruitJob: "行业",
        sex: "性别",
        // age: "年龄",
        education: "学历要求",
        // companyExperience: "工作经验",
      },
      filter: {
        age: "",
        companyExperience: "",
        education: "",
        jobName: "",
        recruitJob: "",
        sex: "",
        jobCode: "",
        pageNum: 1,
        pageSize: 9,
      },
      subType: null,
    };
  },
  name: "Recruit",
  components: {
    Head,
    Footer,
    Resume,
  },
  created() {
    this.getrecommend();
    this.getcodition();
    this.getSearch();
  },
  watch: {},
  methods: {
    // 查询条件点击
    filterClick(type, value) {
      if (this.subType) {
        this.filter[this.subType] = null;
      }
      this.subType = type;
      this.filter[type] = value;
      this.filter.jobName=null
      this.$route.query.value = null;
      this.$refs.headSearch.searchVal = null;
      this.getSearch();
    },
    // 查询条件
    getSearch() {
      if (this.$route.query.value) {
        this.filter = {
          pageNum: 1,
          pageSize: 9,
          jobName: this.$route.query.value,
        };
      }
      search(this.filter).then((res) => {
        this.searchRes = res.rows;
        this.total = res.total;
      });
    },
    sizeChange(size) {
      this.filter.pageNum = 1;
      this.filter.pageSize = size;
      this.getSearch();
      setTimeout(() => {
        document.documentElement.scrollTop = 245;
      });
    },
    currentChange(num) {
      this.filter.pageNum = num;
      this.getSearch();
      setTimeout(() => {
        document.documentElement.scrollTop = 245;
      });
    },
    // 查询条件
    getcodition() {
      codition().then((res) => {
        res.data.jobCode = [];
        this.searchFilter = res.data;
      });
    },
    // 推荐
    getrecommend() {
      recommend({ pageNum: 1, pageSize: 21 }).then((res) => {
        this.recommendList = res.rows;
      });
    },
    // 筛选职位
    filterIobCode(index, id) {
      if (index == "recruitJob") {
        this.filterText = {
          recruitJob: "行业",
          //   jobCode: "职位",
          sex: "性别",
          //   age: "年龄",
          education: "学历要求",
          //   companyExperience: "工作经验",
        };
        jobListPage({ parentId: id }).then((res) => {
          this.searchFilter.jobCode = res.data;
        });
        console.log(this.searchFilter.jobCode);
      }
    },
    // 隐藏
    hideJobCode(index) {
      if (index == "recruitJob") {
        this.filterText = {
          recruitJob: "行业",
          sex: "性别",
          //   age: "年龄",
          education: "学历要求",
          //   companyExperience: "工作经验",
        };
        this.filter.jobCode = "";
        this.getSearch();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;
}

.home .main {
  min-height: calc(100% - 278px);
}

.home .banner img {
  width: 100%;
}

.home .activity {
  margin: 20px 0 40px 0;
  flex-wrap: wrap;
}

.home .activity div {
  width: 386px;
  height: 217px;
}

.home .activity div img {
  width: 100%;
}

.home .tabStyle {
  margin-bottom: 20px;
}

.title-tag {
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 45%,
    #ffffff 100%
  );
  padding: 10px 0;
  margin-bottom: 20px;
}

.cont {
  margin-right: -20px;
  margin-bottom: 40px;
  overflow: hidden;
}

.item {
  width: 386px;
  height: 180px;
  background: #ffffff;
  border-radius: 0;
  opacity: 1;
  float: left;
  overflow: hidden;
  text-align: left;
  padding: 16px;
  box-sizing: border-box;
  margin: 0 20px 20px 0;

  img {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    float: left;
  }

  .text {
    overflow: hidden;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    padding-left: 16px;
    padding-top: 10px;
    height: 62px;

    p {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }

  .bottom {
    margin-top: 16px;
    padding-top: 16px;
    clear: both;
    border-top: 1px solid #eee;
    font-size: 14px;
    font-weight: 400;
    color: #666666;

    h6 {
      font-size: 14px;
      color: #666666;
      font-weight: normal;
      margin-bottom: 3px;
      overflow: hidden;
    }

    p {
      color: #999999;
      overflow: hidden;
    }

    .btn {
      width: 76px;
      height: 32px;
      background: linear-gradient(171deg, #7cc3ff 0%, #3580df 100%);
      border-radius: 16px 16px 16px 16px;
      opacity: 1;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      float: right;
      margin-top: 14px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.tj {
  overflow: hidden;
  text-align: left;
  padding: 0;
  background: #fff;
  margin-bottom: 40px;
  margin-top: 20px;

  li {
    padding: 13.5px 16px;
    line-height: 20px;
    border-bottom: 1px solid #eeeeee;

    &:last-child {
      border: 0;
    }

    > span {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      float: left;
      width: 96px;
    }

    .nr {
      overflow: hidden;
      font-size: 14px;
      font-weight: 400;
      color: #666666;

      span {
        margin-right: 24px;
        cursor: pointer;
        white-space: nowrap;
        line-height: 24px;

        &.atthis {
          color: #0780e6;
        }
      }
    }
  }
}
</style>
